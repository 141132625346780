
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/header.vue'; // @ is an alias to /src
import SidebarPurchase from '@/components/sidebar-purchase.vue'; // @ is an alias to /src
import AccountService from '@/services/account-service';
import BuiltinService from '@/services/buitin-service';
import purchaseServices from '@/services/purchase-services';
import productService from '@/services/product-service';
import projectService from "@/services/project-service";
import accountService from "@/services/account-service";
import UserManagementService from "@/services/user-management";
import utils from "@/utils/utils";
import PopUp from "@/components/PopUp.vue";
@Component({
  components: {
    Header,
    SidebarPurchase,
    PopUp
  },
})
export default class MaterialReceipts extends Vue {
  receipt_no:any=""
  fieldType:any=""
  error:any="";
  payload: any = {}
  items: any = [];
  charge_deduct_info: any = []
  other_cost_info: any = []
// For Modal
modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalType = "";
        this.modalSimpleMessage = ""
    }
    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
  other_info: any = {}
  getReceipt(receipt_no: string) {
    if(this.receipt_no === undefined || this.receipt_no === ""){
      this.error = 'Please enter receipt number.'
      this.fieldType='search'
    }else{
    purchaseServices.getReceiptRequest(receipt_no).then((response) => {
      this.payload = response.data['purchase_receipts']
      this.items = response.data['items']
      this.other_info = response.data['purchase_info']
      this.charge_deduct_info = response.data['charges_and_deduction']
      this.other_cost_info = response.data['other_cost']
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
  }
  cancel() {
    if(this.receipt_no === undefined || this.receipt_no === ""){
      this.error = 'Please enter receipt number.'
      this.fieldType='cancel';
    }else{
    this.payload['items'] = this.items
    purchaseServices.cancelReceiptRequest(this.payload.id).then((resp) => {
      this.showModal('Cancelled Successfully', [], "success");
      this.reset();
      this.receipt_no="";
      this.error="";
    }).catch((e) => {
        console.log('Unexpected error:', e);
        this.showModal('An unexpected error occurred', [], "error");
      });
    }
  }
  reset(){
    this.payload = {};
    this.error="";
    this.items=[];
    this.receipt_no="";
    this.other_cost_info=[]
    this.other_info=[]
  }
  downloadDocument(url: string) {
    const anchor = document.createElement("a");
    const fileUrl = process.env.VUE_APP_SERVER + url;

    // Check if the URL is valid and the file exists
    fetch(fileUrl)
      .then(response => {
        if (response.status === 200) {
          // The file exists, so we can proceed with the download
          anchor.href = fileUrl;
          anchor.download = "downloaded_file_name"; // You can specify the name of the downloaded file here
          anchor.target = "_blank";

          document.body.appendChild(anchor);
          anchor.click();

          document.body.removeChild(anchor);
          this.showModal("File Downloaded",[], "success")
        } else {
          this.showModal('File not found or URL is not valid.',[], "error")
          // Handle the case where the file does not exist
        }
      })
      .catch(error => {
        // Handle other network-related errors
        this.showModal("An error occurred while fetching the file:",[], "error");
      });
  }
}
